.campusAmb {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2.25rem;
}

.campusAmb__container {
    padding: 3rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    background: #02121C;
    box-shadow: 12px 14px 8px -5px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    position: relative;
}

.excel_outline {
    position: absolute;
    right: 0;
    height: 100%;
    pointer-events: none;
    z-index: 9;
    opacity: 0.1;
    transform: scale(1.05);
    bottom: 30px;
}

.campusAmb__left {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.campusAmb__left img {
    width: 100%;
}

.campusAmb__right {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 1rem;
}

.campusAmb__right h1 {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    text-align: center;    
    color: #FFFFFF;
}
.campusAmb__right p {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 300;
    font-size: 1.25rem;
    text-align: center;  
    color: #FFFFFF;
    width: 90%;
}

.campus__btn {
    outline: none;
    border: none;
    background: #D9D9D9;
    border-radius: 12px;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 1.45rem;
    color: #002C49;
    padding: 0.5rem 1.8rem;
    margin-top: 1.5rem;
    cursor: pointer;
}



@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {
    .campusAmb__container {
        padding: 2rem;
        width: 90%;
    }
    .campusAmb__right h1 {
        font-size: 2.5rem;
    }
    .excel_outline {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .campusAmb {
        padding: 2rem;
        height: 100%;
    }
    .campusAmb__container {
        padding: 2rem;
        width: 100%;
        flex-direction: column;
    }

    .campusAmb__left img {
        width: 200px;
    }
    .campusAmb__right h1 {
        font-size: 2rem;
    }
    .campusAmb__right p {
        font-size: 1.05rem;
    }
    .campus__btn {
        font-size: 1.25rem;
        padding: 0.5rem 1.2rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .campusAmb {
        padding: 1rem;
    }
}

@media screen and (max-width: 400px) {

}

