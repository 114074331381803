.home {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  min-height: 100vh;
  position: relative;
}

.home__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: auto;
  padding: 0 2rem;
}

.home_v_1, .home_v_2, .home_v_3, .home_v_4, .home_v_5, .home_v_6 {
  width: 15px;
  pointer-events: none;
  position: absolute;
  animation: up-down linear 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: up-down linear 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: up-down linear 4s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: up-down linear 4s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: up-down linear 4s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

.home_v_1 {
    top: 5%;
    left: 5%;
}
.home_v_2 {
    bottom: 0;
    left: 5%;
}
.home_v_3 {
    top: 20%;
    left: 45%;
}
.home_v_4 {
    top: 0;
    right: 5%;
}
.home_v_5 {
  bottom: -5%;
}
.home_v_6 {
    bottom: 10%;
    right: 5%;
}

.home__left {
  flex: 0.55;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  position: relative;
}
.home__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 9;
}

.home_logo_excel {
  width: 70%;
}

.excel_banner {
  width: 70%;
  pointer-events: none;
}

.home__date {
  width: 160px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1rem;
  position: relative;
  z-index: 9;
}

.home__date_border {
  border: 6px solid #6bbcf1;
  mix-blend-mode: overlay;
  width: 160px;
  height: 120px;
  position: absolute;
}

.home__date h1 {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
}
.home__date h2 {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
}

.home__right {
  flex: 0.45;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.home_animation {
  position: absolute;
  z-index: 1;
  width: 600px;
  pointer-events: none;
}

.home_mascot {
  width: 50%;
  z-index: 9999;
}




@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

  .home_animation {
    width: 550px;
  }
  
}

@media only screen and (max-width: 1200px) {
  .home_v_1, .home_v_2, .home_v_3, .home_v_4, .home_v_5, .home_v_6 {
    width: 12px;
  }
  
  .home_animation {
    width: 520px;
  }
}

@media only screen and (max-width: 1100px) {
  .home__container {
    width: 96%;
    margin: 0 auto;
    padding: 0 1rem;
    height: 100%;
  }
  .home_animation {
    width: 480px;
  }
}

@media screen and (max-width: 992px) {
  .home {
    align-items: flex-start;
    min-height: 100vh;
  }
  .home__container {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 4rem 1.5rem;
    gap: 3rem;
  }
  .home_v_1, .home_v_2, .home_v_3, .home_v_4, .home_v_5, .home_v_6 {
    width: 10px;
  }
  /* .home__right {
    display: none;
  } */
  .home__left {
    flex: 1;
    gap: 3rem;
  }
  .home__logo {
    align-items: flex-start;
    gap: 1rem;
    z-index: 9;
  }
  .home_logo_excel {
    width: 250px;
  }
  
  .excel_banner {
    width: 250px;
  }
  .home_animation {
    width: 500px;
  }
}

@media screen and (max-width: 800px) {
  .home__container {
    flex-direction: column;
    width: 100%;
    padding: 4rem 1.5rem;
  }
  .home_v_1 {
    top: 5%;
    left: 5%;
  }
  .home_v_2 {
    bottom: 5%;
    left: 5%;
  }
  .home_v_3 {
    top: 20%;
    left: 45%;
  }
  .home_v_4 {
    top: 0;
    right: 5%;
  }
  .home_v_5 {
    bottom: 0%;
  }
  .home_v_6 {
    bottom: 15%;
    right: 5%;
  }
  .home__left {
    gap: 2rem;
  }
  .home__date {
    transform: scale(0.9);
  }
  .home_logo_excel {
    width: 250px;
  }
  .excel_banner {
    width: 250px;
  }
  .home_animation {
    width: 400px;
  }
}

@media screen and (max-width: 600px) {
  .home {
    align-items: center;
    min-height: 100vh;
  }
  .home__container {
    padding: 4rem 1rem;
    gap: 4rem;
  }
  .home__left {
    gap: 1rem;
  }
  .home__logo {
    align-items: center;
  }
  .home_logo_excel, .excel_banner {
    width: 70%;
  }
  .home__date {
    transform: scale(0.85);
  }
  .home_logo_excel {
    width: 70%;
  }
  .excel_banner {
    width: 70%;
  }

  .home_animation {
    width: 350px;
  }
  .home_mascot {
    width: 100px;
  }
}

@media screen and (max-width: 400px) {
  .home_animation {
    width: 300px;
  }
}



/* ANIMATIONS */
@keyframes up-down {
    0% {
      transform:  translate(1px,35px)  ;
    }
    24% {
      transform:  translate(1px,45px)  ;
    }
    50% {
      transform:  translate(1px,23px)  ;
    }
    74% {
      transform:  translate(1px,30px)  ;
    }
    100% {
      transform:  translate(1px,30px)  ;
    }
}
  
@-moz-keyframes up-down{
    0% {
      -moz-transform:  translate(1px,35px)  ;
    }
    24% {
      -moz-transform:  translate(1px,45px)  ;
    }
    50% {
      -moz-transform:  translate(1px,23px)  ;
    }
    74% {
      -moz-transform:  translate(1px,30px)  ;
    }
    100% {
      -moz-transform:  translate(1px,30px)  ;
    }
}
  
@-webkit-keyframes up-down {
    0% {
      -webkit-transform:  translate(1px,35px)  ;
    }
    24% {
      -webkit-transform:  translate(1px,45px)  ;
    }
    50% {
      -webkit-transform:  translate(1px,23px)  ;
    }
    74% {
      -webkit-transform:  translate(1px,30px)  ;
    }
    100% {
      -webkit-transform:  translate(1px,30px)  ;
    }
}
  
@-o-keyframes up-down {
    0% {
      -o-transform:  translate(1px,35px)  ;
    }
    24% {
      -o-transform:  translate(1px,45px)  ;
    }
    50% {
      -o-transform:  translate(1px,23px)  ;
    }
    74% {
      -o-transform:  translate(1px,30px)  ;
    }
    100% {
      -o-transform:  translate(1px,30px)  ;
    }
}
  
@-ms-keyframes up-down {
    0% {
      -ms-transform:  translate(1px,35px)  ;
    }
    24% {
      -ms-transform:  translate(1px,45px)  ;
    }
    50% {
      -ms-transform:  translate(1px,23px)  ;
    }
    74% {
      -ms-transform:  translate(1px,30px)  ;
    }
    100% {
      -ms-transform:  translate(1px,30px)  ;
    }
}