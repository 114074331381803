.competetion__type__card {
  width: 160px;
  height: 100px;
  background: #263743;
  box-shadow: 0px 1.34959px 8.43496px 2.36179px rgba(255, 255, 255, 0.09);
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 20.58px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.image__competetion {
  width: 65px;
}
.image__container {
  width: 90px;
  height: 90px;
  border-radius: 58.89px;
  background-color: #263743ba;
  position: absolute;
  top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 1.25rem;
  position: relative;
  top: 25px;
  color: rgba(248, 252, 255, 1);
  font-weight: 700;
  font-family: var(--secondary-font);
  text-transform: uppercase;
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
  .competetion__type__card {
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 1100px) {
}

@media screen and (max-width: 992px) {
  .competetion__type__card {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 400px) {
}
