.team_manage_dialog {
    background-color: #041c2c;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;  
}

.tmd_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    width: 100%;
}

.tmd_name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.tmd_name h2 {
    font-family: var(--secondary-font);
    font-size: 1.225rem;
    color: #ffffff;
    font-weight: 400;
    display: flex;
    align-items: center;
    width: 45%;
}

.tmd_name span {
    color: #9EEFB3;
    font-weight: 600;
    font-family: var(--secondary-font);
    text-transform: uppercase;
    font-size: 1.525rem;
    width: 45%;
}

.tmd_icon {
    cursor: pointer;
    color: #ffffff;
    font-size: 1.25rem;
    margin-left: 0.5rem;
    width: 10%;
    flex-shrink: 0;
}

table, th, td {
    border: 3px solid #9eefb3;;
    border-collapse: collapse;
    border-radius: 100px;
    padding: 0.5rem;
  }
  th{
    font-family:var(--primary-font);
  }

  .no_team_msg{
    color: #9EEFB3;
    font-family:var(--primary-font);
  }
  .team__table{
    color: #ffff;
    padding: 1rem;
    width: 100%;
    font-family: var(--secondary-font);;
  }
