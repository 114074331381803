.registeredEvents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem;
}
.registeredEvents h2 {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 1.75rem;
    text-align: center;
    color: #FFFFFF;
    margin-top: 1.5rem;
}

.re__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    width: 100%;
    margin-top: 1.5rem;
}

.regEvCard {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    width: 450px;
    background-color: #041C2C;
    gap: 1rem;
}

.rec_left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex: 0.2;
}

.rec_left img {
    width: 80px;
}

.rec_right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0.8;
    font-family: var(--secondary-font);
    gap: 0.3rem;
}

.rec_right h3 {
    color: #9EEFB3;
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
}

.rec_right_section {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.rec_right_section p {
    width: 50%;
    color: #ffffff;
    font-size: 1.05rem;
}



@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1100px) {
}

@media screen and (max-width: 992px) { 
    .regEvCard {
        padding: 1rem;
        width: 400px;
    }
}

@media screen and (max-width: 800px) {
    .regEvCard {
        padding: 1rem;
        width: 350px;
        gap: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .regEvCard {
        padding: 0.75rem;
        width: 320px;
        gap: 0.75rem;
    }
    .rec_right h3 {
        font-size: 1.15rem;
    }
    
    .rec_right_section p {
        font-size: 0.9rem;
    }
    
}

@media screen and (max-width: 400px) {
}

