.gallery {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
}

.gallery__grid {
  --column-width: 250px;
  /* display: flex;
    flex-wrap: wrap;
    flex-direction: row; */
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--column-width));
  gap: 16px;
  justify-content: center;
  /* max-width: 80vw; */
  max-width: 90vw;
  margin: 10px auto;
}

.gallery__grid .gallery__image {
  /* flex: 20%; */
  /* max-height: 400px; */
  max-width: var(--column-width);
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
}

.gallery__grid .gallery__image:nth-child(1n)
/* ,.gallery__grid .gallery__image:nth-child(1n) img */ {
  height: 200px;
  grid-row-end: span 2;
}

.gallery__grid .gallery__image:nth-child(2n)
/* ,.gallery__grid .gallery__image:nth-child(2n) img  */ {
  height: 310px;
  grid-row-end: span 3;
}

.gallery__grid .gallery__image:nth-child(3n)
/* ,.gallery__grid .gallery__image:nth-child(3n) img */ {
  height: 420px;
  grid-row-end: span 4;
}

.gallery__grid .gallery__image:nth-child(4n)
/* ,.gallery__grid .gallery__image:nth-child(4n) img */ {
  height: 530px;
  grid-row-end: span 5;
}

.gallery__grid .gallery__image,
.gallery__grid .gallery__image img {
  transition: all 0.5s ease-in-out;
}

.gallery__grid .gallery__image:hover {
  box-shadow: 0 0 40px rgba(135, 206, 235, 0.3);
}

.gallery__grid .gallery__image img {
  /* width: 100%;
  height: 100%; */
  flex: 1;
  cursor: pointer;
}

.gallery__grid .gallery__image img:hover {
  border-radius: 20px;
  transform: scale(1.1);
}

.gallery__slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  cursor: default;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 5, 0.8);
  z-index: 999;
}

.gallery__slider .btn__close,
.gallery__slider .btn__prev,
.gallery__slider .btn__next {
  position: fixed;
  cursor: pointer;
  opacity: 0.5;
  color: #fff;
  z-index: 9999;
  transition: 0.2s;
}

.btn__next:hover,
.btn__prev:hover,
.btn__close:hover {
  opacity: 0.8;
  cursor: pointer;
}

.gallery__slider .btn__close {
  scale: 1.5;
  top: 40px;
  right: 40px;
}

.gallery__slider .btn__prev {
  scale: 1.5;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.gallery__slider .btn__next {
  scale: 1.5;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.gallery__image__view {
  width: 95vw;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery__image__view img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 992px) {
  .gallery__grid .gallery__image {
    max-width: 300px;
    flex: 50%;
  }
}
