.aeva {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 0;
}
.aeva_container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
    margin: auto;
}

.aeva_details {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.ad_head1 {
    font-size: 5rem;
    color: #fff;
    font-family: var(--primary-font); 
}

.ad_head1 span {
    background: #366CA7;
}

.ad_para {
    font-size: 1.25rem;
    color: #ffffff;
    font-weight: 400;
    font-family: var(--secondary-font);
}

.aeva_imagecontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.ai_image {
    position: absolute;
    top: 0;
    width: 450px;
}


@media only screen and (max-width: 1300px) {
    .aeva_container {
        width: 86%;
    }
    .ai_image {
        width: 420px;
    }
}

@media only screen and (max-width: 1200px) {
    .aeva_container {
        width: 90%;
    }
    .ai_image {
        width: 400px;
    }
}

@media only screen and (max-width: 1100px) {
    .aeva_container {
        width: 94%;
    }
    .ai_image {
        width: 380px;
    }
    .ad_head1 {
        font-size: 4rem;
    }
}

@media screen and (max-width: 992px) {
    .aeva_container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
    }
    .ad_head1 {
        text-align: center;
    } 
    .aeva_imagecontainer {
        margin-top: 1.25rem;
    }
    .ai_image {
        width: 350px;
    }

    .ad_para {
        text-align: center;
    }
    .ad_head1 {
        font-size: 3rem;
    }

}

@media screen and (max-width: 800px) {
    .ad_head1 {
        font-size: 2.75rem;
    }
    .ad_para {
        font-size: 1.05rem;
    }
    .ai_image {
        width: 325px;
    }
}

@media screen and (max-width: 600px) {
    .aeva_container {
        padding: 1rem;
    }
    .ai_image {
        width: 300px;
    }
}

@media screen and (max-width: 400px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}
