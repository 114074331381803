.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    min-height: 60vh;
    overflow: hidden;
}

.banner__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.banner_line {
    position: absolute;
    width: 100%;
    pointer-events: none;
}
.banner_line_mob {
    position: absolute;
    width: 100%;
    pointer-events: none;
    display: none;
}

.banner_card {
    width: 220px;
    height: 220px;
    background: #081823;
    border: 5px solid #FFFFFF;
    border-radius: 15px;
    z-index: 1;
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
}

.banner_card_bg {
    position: absolute;
    opacity: 0.05;
    transform: rotate(-21.25deg);
    width: 60%;
    z-index: -1;
    pointer-events: none;
}

.bc_1 {
    left: -20%;
    margin-top: -5%;
}
.bc_2 {
    margin-top: 10%;
}
.bc_3 {
    right: -20%;
    margin-top: -5%;
}

.banner_card_img {
    width: 60px;
}

.banner_card_line {
    width: 80%;
    pointer-events: none;
}

.banner_card_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    gap: 0.75rem;
}

.banner_card_info1 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: var(--secondary-font);
}

.banner_card_info2 {
    color: #8ED2FF;
    font-size: 1.25rem;
    font-family: var(--secondary-font);
    font-weight: 700;
}


@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {
    .banner_card {
        width: 210px;
        height: 210px;
    }
}

@media only screen and (max-width: 1200px) {
    .banner_card {
        width: 200px;
        height: 200px;
        gap: 1rem;
    }
}

@media only screen and (max-width: 1100px) {
    .banner_card {
        width: 190px;
        height: 190px;
    }

    .banner_card_img{
        width: 50px;
    }
}

@media screen and (max-width: 992px) {
    .banner {
        padding: 2rem 0;
        min-height: 100vh;
    }
    .banner_line {
        display: none;
    }
    .banner__container {
        flex-direction: column;
        gap: 2rem;
    }
    .banner_card {
        width: 180px;
        height: 180px;
        border: 3px solid #FFFFFF;
    }
    .bc_1 {
        left: initial;
        margin-top: 0;
    }
    .bc_2 {
        margin-top: 0;
    }
    .bc_3 {
        right: initial;
        margin-top: 0;
    }
}

@media screen and (max-width: 800px) {
    .banner_card {
        width: 160px;
        height: 160px;
        gap: 0.8rem;
    }

    .banner_card_img{
        width: 45px;
    }

    .banner_card_details{
        gap: 0.6rem;
    }

    .banner_card_info1{
        font-size: 1.4rem;
    }

    .banner_card_info2{
        font-size: 1.1rem;
    }

}

@media screen and (max-width: 600px) {
    .banner__container {
        flex-direction: column;
        gap: 3rem;
    }
    .banner_line_mob {
        display: block;
    }
    .banner_card {
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 400px) {

}