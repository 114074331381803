.compTab {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    margin-top: 2.5rem;
    overflow: scroll;
    height: 75vh;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}
.compTab::-webkit-scrollbar {
    display: none;
}


.cAbout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 200;
    font-size: 1.25rem;
}
.cAbout p {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 200;
    font-size: 1.25rem;
}
.cAbout h2 {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 1.05rem;
    color: #9EEFB3;
    margin: 1rem 0;
    margin-bottom: 1.5rem;
    padding: 0.75rem 1rem;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
}
.ctab__text {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 200;
    font-size: 1.25rem;
    text-align: left;
    color: #FFFFFF;
}
.ctab__list {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 200;
    font-size: 1.25rem;
    text-align: left;
    color: #FFFFFF;
}

.cButton {
    width: 100%;
    margin: 1rem 0;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.a_cb_btn {
    background-color: #9EEFB3; 
    color: #041C2C;
    border-radius: 5px;
    font-family: var(--secondary-font);
    padding: 0.75rem 1.75rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.05rem;
    cursor: pointer;
    outline: none;
    border: none;
}

.cabout_btns {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 2rem;
}

.cabout__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    padding: 0.75rem 1.75rem;
    font-family: var(--secondary-font);
}

.cabout__btn h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    color: #9EEFB3;   
    white-space: nowrap;
}

.cabout__btn h6 {
    font-weight: 300;
    font-size: 27.6043px;
    font-size: 1.15rem;
    color: #FFFFFF;
    white-space: nowrap;
}

.cContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
}

.cContact_card {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 15px;
    width: 450px;
    height: 90px;
    display: flex;
    align-items: center;
    padding: 1.25rem;
}

.ccc_left {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: var(--secondary-font);
    gap: 0.225rem;
}
.ccc_left h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    color: #FFFFFF;
}
.ccc_left p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.05rem;
    color: #9EEFB3;
}

.ccc_right {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.ccc_right a {
    text-decoration: none;
    cursor: pointer;
    width: 44px;
    height: 44px;
    border-radius: 100px;
    background: #FFFBFB;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ccc_icon {
    color: #041C2C;
    font-size: 1.5rem;
}

.ecd__right_content {
    width: 100%;
    display: flex;
}


.confirm__book_que {
    background-color: #041c2c;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
}

.referral__input {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    padding: 0.75rem;
    font-family: var(--secondary-font);
    color: #9EEFB3;
    font-size: 0.9rem;
    outline: none;
    border: 1px solid #9EEFB3;
    width: 100%;
}

.confirm__book_que p {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 1.05rem; 
    color: #FFFFFF;
}

.register_confirm_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
}

.register_confirm_btn button {
    background-color: #9EEFB3; 
    color: #041C2C;
    border-radius: 5px;
    font-family: var(--secondary-font);
    padding: 0.55rem 1.55rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    border: none;
}

.teamCreate {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.teamCreate__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.25rem;
}

.teamCreate input {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    padding: 0.75rem;
    font-family: var(--secondary-font);
    color: #9EEFB3;
    font-size: 0.9rem;
    outline: none;
    border: 1px solid #9EEFB3;
}

.teamCreate__btns button {
    background-color: #9EEFB3; 
    color: #041C2C;
    border-radius: 5px;
    font-family: var(--secondary-font);
    padding: 0.55rem 1.55rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    border: none;
}

.register_team_form {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.register_team_form input {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    padding: 0.75rem;
    font-family: var(--secondary-font);
    color: #9EEFB3;
    font-size: 0.9rem;
    outline: none;
    border: 1px solid #9EEFB3;
}
.register_team_form button {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    padding: 0.75rem;
    font-family: var(--secondary-font);
    color: #9EEFB3;
    font-size: 0.9rem;
    outline: none;
    border: 1px solid #9EEFB3;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) and (max-height: 700px) {
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1100px) {
}

@media screen and (max-width: 992px) {
    .compTab {
        padding: 0rem;
        margin-top: 0rem;
        height: 60vh;
    }
    .cAbout h2 {
        font-size: 1.05rem;
        margin: 1rem 0;
        margin-bottom: 0.255rem;
    }

    .cabout_btns {
        gap: 3rem;
        margin-top: 2rem;
    }
    .cContact_card {
        width: 400px;
        height: 90px;
        padding: 1.25rem;
    }
    .register_team_form {
        flex-direction: column;
    }
}

@media screen and (max-width: 800px) {
    .cAbout, .cAbout p, .cAbout li, .ctab__list li {
        font-size: 1.05rem;
    }
    .cabout_btns {
        gap: 2.5rem;
    }
    .cabout__btn {
        padding: 0.5rem 0.75rem;
    }

    .cabout__btn h5 {
        font-size: 1rem;
    }
    .cabout__btn h6 {
        font-size: 0.9rem;

    }

    .cContact_card {
        width: 330px;
        padding: 1.15rem;
    }
}

@media screen and (max-width: 600px) {
    .cabout_btns {
        gap: 1.5rem;
    }
    .cContact_card {
        width: 280px;
        padding: 1.15rem;
    }
    .ccc_left h1 {
        font-size: 1.05rem;
    }
    .ccc_left p {
        font-size: 0.95rem;
    }
    .ccc_right {
        justify-content: flex-end;
    }
    .ccc_right a {
        width: 38px;
        height: 38px;
    }
    .ccc_icon {
        font-size: 1.15rem;
    }
}

@media screen and (max-width: 400px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}
