.partners {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.partners__container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    color: #ffffff;
    width: 90%;
    margin: 0 auto;
    position: relative;
}

.partners_title {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 800;
    font-size: 5rem;
    line-height: 100%;
    color: #FFFFFF;
}

.partners__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4rem;
    width: 100%;
    margin-top: 4rem;
}

.partner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    gap: 0.5rem;
}
.partner img {
    height: 140px;
}
.partner h2 {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    color: #FFFFFF;
    
}

.sponsors__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    margin-top: 4rem;
}

.sponsor {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sponsor img {
    width: 170px;
    object-fit: cover;
}



@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {
    .partners__container {
        padding: 2rem;
        width: 100%;
    }
    .partners_title {
        font-size: 4rem;
        line-height: 100%;
        color: #FFFFFF;
    }
}

@media screen and (max-width: 992px) {
    .partners {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: 100%;
        height: 100%;
        padding: 2rem 0;
    }
    .partners__content {
        gap: 4rem;
        width: 100%;
        margin-top: 2rem;
    }
    .partners_title {
        font-size: 3rem;
        line-height: 100%;
    }
    .partner img {
        height: 130px;
    }
    .partner h2 {
        font-size: 1.35rem;
    }
    .sponsor img {
        width: 150px;
    }
}

@media screen and (max-width: 800px) {
    .partner img {
        height: 120px;
    }
    .partners__content {
        gap: 2.5rem;
        width: 100%;
        margin-top: 2rem;
    }
    .sponsor img {
        width: 130px;
    }
}

@media screen and (max-width: 600px) {
    .partners_title {
        font-size: 2.75rem;
    }
    .partner img {
        height: 120px;
    }
    .partner h2 {
        font-size: 1.15rem;
    }
    .sponsor img {
        width: 110px;
    }
}

@media screen and (max-width: 400px) {

}