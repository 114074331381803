.card_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  height: 180px;
  width: 180px;
  background: #263743;
  box-shadow: 0px 1.34959px 8.43496px 2.36179px rgba(255, 255, 255, 0.09);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.card__bkmrk {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.65rem;
  color: #9eefb3;
  cursor: pointer;
  z-index: 9;
}

.card__img {
  width: 80%;
  opacity: 0.7;
}

.card:hover .card__img {
  opacity: 1;
}

.card__text {
  color: #ffffff;
  margin-top: 10px;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 45px;
  cursor: pointer;
}

.eventsCard__dialog {
  background-image: url("../../assets/svg/cardBg.svg");
  height: 90vh;
  background-color: #041c2c;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem;
  display: flex;
  align-items: flex-start;
}

.ecd__left {
  flex: 0.2;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border-right: 5px solid #072b42;
  height: calc(90vh - 4rem);
  padding: 0 1rem;
  color: #fafdff;
}

.ecd__left_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}

.ecd__left_header img {
  width: 110px;
}

.ecd__header_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ecd__header_text h2 {
  display: none;
}

.ecd__header_text h3 {
  font-family: var(--secondary-font);
  font-style: normal;
  font-style: italic;
  font-weight: 600;
  font-size: 1.1rem;
  color: #fafdff;
  margin-top: 1rem;
}

.ecd__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.ecd_btn {
  border-radius: 10px;
  width: 160px;
  height: 45px;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 1.15rem;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fafdff;
  background: none;
}
.ecd_btn_selected {
  background: rgba(255, 255, 255, 0.08);
}

.ecd__right {
  flex: 0.8;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #fafdff;
  padding-top: 2rem;
}

.ecd__name {
  position: absolute;
  top: -2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.08);
  border-radius: 0px 0px 40px 40px;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  color: #fafdff;
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ecd__name span {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 34px;
  color: #9eefb3;
  font-family: var(--secondary-font);
}

@media only screen and (max-width: 1200px) and (max-height: 700px) {
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1100px) {
  .card {
    height: 160px;
    width: 160px;
  }
}

@media screen and (max-width: 992px) {
  .eventsCard__dialog {
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .eventsCard__dialog::-webkit-scrollbar {
    display: none;
  }

  .ecd__left {
    align-items: center;
    gap: 1rem;
    border-right: none;
    height: calc(90vh - 4rem);
    padding: 0 1rem;
    color: #fafdff;
    width: 100%;
  }
  .ecd__left_header {
    margin: 0 1.5rem;
    flex-direction: row;
  }
  .ecd__nav {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    width: 600px;
    overflow: scroll;
    margin: 0 1.5rem;
    border-top: 4px solid #072b42;
    border-bottom: 4px solid #072b42;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ecd__header_text h2 {
    display: block;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 1.45em;
    color: #fafdff;
    margin-top: 1rem;
  }
  .ecd__header_text h3 {
    font-weight: 700;
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  .ecd__nav::-webkit-scrollbar {
    display: none;
  }

  .ecd__name {
    display: none;
  }
  .ecd_btn {
    width: 90px;
    font-weight: 500;
    font-size: 1.05rem;
    flex-shrink: 0;
    border-radius: 0;
  }
  .ecd_btn_selected {
    background-color: #072b42;
  }

  .ecd__right {
    padding: 1rem 0;
    margin-top: 0rem;
    height: 60vh;
  }
}

@media screen and (max-width: 800px) {
  .card__text {
    font-size: 20px;
    line-height: 45px;
  }
  .eventsCard__dialog {
    padding: 1rem;
  }
  .ecd__nav {
    width: 500px;
    padding-left: 3rem;
  }
  .ecd_btn {
    width: 80px;
    height: 40px;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .ecd__left_header img {
    width: 80px;
  }
}

@media screen and (max-width: 600px) {
  .card {
    min-height: 200px;
    min-width: 200px;
  }
  .ecd__nav {
    width: 350px;
    gap: 0.5rem;
  }
  .ecd__left_header img {
    width: 60px;
  }
  .ecd_btn {
    width: 60px;
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 400px) {
  .ecd__nav {
    width: 300px;
  }
}
