* {
    margin: 0;
    box-sizing: border-box;
}

ul {
    padding-inline-start: 20px;
}

@font-face {
    font-family: "Rhizome";
    src: local("Rhizome"),
        url("./assets/fonts/Rhizome/Rhizome-BoldTrial.otf") format("opentype"),
        url("./assets/fonts/Rhizome/Rhizome-DemiTrial.otf") format("opentype"),
        url("./assets/fonts/Rhizome/Rhizome-ExtraLightTrial.otf") format("opentype"),
        url("./assets/fonts/Rhizome/Rhizome-HeavyTrial.otf") format("opentype"),
        url("./assets/fonts/Rhizome/Rhizome-LightTrial.otf") format("opentype"),
        url("./assets/fonts/Rhizome/Rhizome-RegularTrial.otf") format("opentype"),
        url("./assets/fonts/Rhizome/Rhizome-UltraLightTrial.otf") format("opentype");
    font-weight: 100;
}
@font-face {
    font-family: "Rhizome";
    src: local("Rhizome"),
        url("./assets/fonts/Rhizome/RhizomeVariable-Trial.ttf") format("truetype"),
        url("./assets/fonts/Rhizome/RhizomeVariable-Trial.woff2") format("woff2");
    font-weight: inherit;
}
@font-face {
    font-family: "Rhizome";
    src: local("Rhizome"),
        url("./assets/fonts/Rhizome/Rhizome-UltraLightTrial.otf") format("opentype");
    font-weight: 100;
}
@font-face {
    font-family: "Rhizome";
    src: local("Rhizome"),
        url("./assets/fonts/Rhizome/Rhizome-ExtraLightTrial.otf") format("opentype");
    font-weight: 200;
}
@font-face {
    font-family: "Rhizome";
    src: local("Rhizome"),
        url("./assets/fonts/Rhizome/Rhizome-LightTrial.otf") format("opentype");
    font-weight: 300;
}
@font-face {
    font-family: "Rhizome";
    src: local("Rhizome"),
        url("./assets/fonts/Rhizome/Rhizome-RegularTrial.otf") format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: "Rhizome";
    src: local("Rhizome"),
        url("./assets/fonts/Rhizome/Rhizome-DemiTrial.otf") format("opentype");
    font-weight: 500;
}
@font-face {
    font-family: "Rhizome";
    src: local("Rhizome"),
        url("./assets/fonts/Rhizome/Rhizome-BoldTrial.otf") format("opentype");
    font-weight: 600;
}
@font-face {
    font-family: "Rhizome";
    src: local("Rhizome"),
        url("./assets/fonts/Rhizome/Rhizome-HeavyTrial.otf") format("opentype");
    font-weight: 700;
}


@font-face {
    font-family: "Groupe Font";
    src: local("Groupe Font"),
        url("./assets/fonts/GroupeFont/GroupeMedium-8MXgn.otf") format("opentype");
    font-weight: inherit;
}

html {
    --scrollbarBG: #081823;
    --thumbBG: #3F7CBF;
  }
body::-webkit-scrollbar {
    width: 12px;
}
body {
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 0;
    border: 3px solid tr;
}

   
/* VARIABLES */
:root {
    --primary-font: 'Groupe Font';
    --secondary-font: 'Rhizome';
    /* --bg-color: #E6F4F4;
    --primary-color: #0B8B8B;
    --secondary-color: #86CECE;
    --text-main: #333333; */
}
  