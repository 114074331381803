.legacy {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.legacy__container {
    padding: 4rem 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: #ffffff;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.landing_section_title {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 800;
    font-size: 5rem;
    line-height: 100%;
    color: #FFFFFF;
    transform: rotate(90deg);
    display: flex;
    flex-direction: column;
    position: absolute;
}

.legacy_title {
    top: 9rem;
}

.light_blue {
    color: #C8DDEC;
}

.legacy_section {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-left: auto;
}
.legacy_section  p {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 130%;
    color: #FFFFFF;
    
}

.legacy_logo_container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 4rem;
    margin-top: 2rem;
}

.legacy_logo {
    cursor: pointer;
    transition: ease-in .2s;
    transform: scale(1);
}

.legacy_logo:hover {
    transform: scale(1.2);
}

.l2015 {
    width: 120px;
}

.l2016 {
    width: 110px;
}

.l2017 {
    width: 150px;
}
.l2018 {
    width: 90px;
}
.l2019 {
    width: 120px;
}
.l2020 {
    width: 120px;
}
.l2021 {
    width: 85px;
}
  


@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {
    .landing_section_title {
        font-size: 5rem;
        color: #FFFFFF;
        transform: translateX(-80px) rotate(90deg);
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {
    .landing_section_title {
        font-size: 4rem;
        color: #FFFFFF;
        transform: translateX(-80px) rotate(90deg);
    }
}

@media screen and (max-width: 992px) {
    .legacy__container {
        padding: 4rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        width: 100%;
    }
    .landing_section_title {
        font-size: 3rem;
        transform: none;
        position: initial;
        align-items: center;
    }
    .legacy_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin-left: initial;
        margin-top: 2rem;
    }
    .legacy_section p {
        font-size: 1.25rem;
        line-height: 130%;
        text-align: justify;
    }
    .legacy_logo_container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        margin-top: 2rem;
    }
    .legacy_logo {
        transform: scale(0.9);
    }
    .legacy_logo:hover {
        transform: scale(1);
    }
}

@media screen and (max-width: 800px) {
    .legacy_section p {
        font-size: 1.15rem;
        line-height: 130%;
        text-align: justify;
    }
    .legacy_logo {
        transform: scale(0.8);
    }
    .legacy_logo:hover {
        transform: scale(0.9);
    }
}

@media screen and (max-width: 600px) {
    .landing_section_title {
        font-size: 2.75rem;
    }
}

@media screen and (max-width: 400px) {

}