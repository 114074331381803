.about {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
}

.about__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 70%;
    margin: auto;
    color: white;
    font-family: var(--secondary-font), sans-serif;
    font-size: 1.2rem;
    text-align: center;
    line-height: 32px;
}

.about__image{
    display: flex;
    position: relative;
}

.about__image__mec{
    width: 70%;
    scale :0.8;
}


.about__image__aevo{
    width: 30%;
    top: 40%;
    right: 12%;
    z-index: 1;
    position: absolute;
    animation: 5s ease-in-out 1s 1 reverse both running hi-aevo;
}

.about__image__aevo:hover{
    animation-play-state: paused;
}

.about__content p {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    font-size: 1.25rem;
    color: #FFFFFF;
}


@keyframes hi-aevo {
  0% {
    transform: scale(1) skewX(-180deg) rotate(5deg);
  }  
  100% {
    transform: translateX(-1100px) translateY(-500px) scale(0.1) skew(0deg) rotate(25deg);
  } 
}

@media only screen and (max-width: 1300px) {
    .about__image__aevo {
        right: 10%;
        top: 35%;
    }

}

@media only screen and (max-width: 1200px) {
    .about__image {
        scale: 0.9;
    }

    .about__image__aevo {
        right: 5%;
        top: 38%;
    }

    .about__body {
        width: 80%;
    }

}

@media only screen and (max-width: 1100px) {
    .about__image__aevo {
        width: 28%;
        right: 7%;
    }

}

@media screen and (max-width: 992px) {
    .about__image__aevo {
        top: 34%;
    }

    .about__body {
        width: 85%;
    }

}

@media screen and (max-width:800px) {
    .about__body{
        font-size: 1rem;
        gap: 0.25rem;
    }
    .about__image__mec{
        scale: 1;
        padding-bottom: 20px;
    }

    .about__image__aevo {
        right: 5%;
        top: 30%;
    }
    .about__content p {
        line-height: 115%;
        font-size: 1.05rem;
    }
    
}

@media screen and (max-width:600px) {
    @keyframes hi-aevo {
        0% {
          transform: translateX(20px) translateY(-140px) scale(1) skewX(-180deg) rotate(5deg);
        }  
        100% {
          transform: translateX(-1000px) translateY(-500px) scale(0.1) skew(0deg) rotate(25deg);
        } 

    }
    .about__image__mec {
        width: 100%;
    }

    .about__body {
        width: 100%;
    }
}