.navbar {
    height: 80px;
    position: fixed;
    padding: 0 2rem;
    z-index: 999;
    top: 0;
    /* width: 100%; */
}
.navbar__main {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

  
a {
    text-decoration: none;
}
  

.nav_hamburger {
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: white;
}
 

.nam_menu_icon {
    font-size: 1.75rem;
    color: #C8DDEC;
}


.navbar__mob {
    background: rgba(1, 9, 14, 0.9);
    border: 3px solid #8EC5FF;
    box-shadow: inset 35.8667px -35.8667px 35.8667px rgba(1, 7, 11, 0.053), inset -35.8667px 35.8667px 35.8667px rgba(255, 255, 255, 0.053);
    backdrop-filter: blur(20px);
    border-radius: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
    position: relative;
}

.navbar_mob_close {
    position: absolute;
    top: 1.6rem;
    left: 2rem;
    font-size: 2rem;
    color: white;
    cursor: pointer;
}

.navbar__mobcontents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1.25rem;
}

.navmob__link {
    gap: 0.95rem;
    font-family: var(--secondary-font);
    font-style: normal;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 1.1rem;
    cursor: pointer;}

.navmob__link img {
    width: 32px;
}

.navmob__link:hover {
    opacity: 1;
}


.nav_btn {
    background: #002C49;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    border: none;
    font-family: var(--secondary-font);
    font-style: normal;
    font-size: 1.2rem;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    padding: 0.5rem 1rem;
}
  
@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 1100px) {
}
  
@media screen and (max-width: 992px) {

}
@media screen and (max-width: 800px) {
    .navbar {
        padding: 0 1rem;
    }
}
  
@media screen and (max-width: 600px) {
    .navbar {
      padding: 0 0.5rem;
    }
    .navbar__mob {
      padding: 3rem 1rem;
      position: relative;
    }
    .nav_hamburger {
        width: 35px;
        height: 35px;
    }
    .navbar_mob_close {
      position: absolute;
      top: 1rem;
      left: 1rem;
      font-size: 2rem;
      color: white;
    }  
    .navmob__link img {
        width: 28px;
    }
}