.unmasked {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4rem 0;
    margin-bottom: 2rem;
}


.unmasked__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
    margin: auto;
    position: relative;
    background: #02121C;
    border: 3px solid #FFFFFF;
    border-radius: 0px 500px 0px 0px;
    padding: 3rem;
    gap: 1rem;
}

.unmasked_left {
    display: flex;
    flex-direction: column;
    flex: 0.7;
    gap: 1rem;
}

.unmasked__title {
    font-size: 3rem;
    color: #ffffff;
    font-family: var(--primary-font); 
    background: #02121C;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
}

.unmasked_left p {
    font-family: var(--secondary-font);
    font-style: normal;
    font-size: 1.25rem;
    color: #ffffff;
    font-weight: 400;
    width: 90%;
}

.unmasked_left h3 {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    color: #C8DDEC;
    margin-top: 0.5rem;
}

.unmk_btn {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    background: #02121C;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    color: #FFFFFF;
    padding: 1rem 2rem;
}

.unmasked_right {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ur__img_div {
    position: relative;
    margin-top: -30%;
    width: 300px;
    border: 3px solid #FFFFFF;
}

.ur__img {
    width: 100%;
}

.ur__img_div h4 {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(90deg, #02121C 46.55%, rgba(2, 18, 28, 0) 178.1%);
    color: #ffffff;
    padding: 3px;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    padding: 1rem;
}



@media only screen and (max-width: 1300px) {
    .unmasked__container {
        width: 86%;
    }
}

@media only screen and (max-width: 1200px) {
    .unmasked__container {
        width: 90%;
    }
}

@media only screen and (max-width: 1100px) {
    .unmasked__container {
        width: 94%;
    }
}

@media screen and (max-width: 992px) {
    .unmasked__container {
        width: 94%;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        border-radius: 0px 250px 0px 0px; 
        padding: 2rem;   
    }
    .unmasked__title {
        font-size: 2.5rem;
        position: relative;
        top: initial;
        transform: initial;
    }
    .unmasked_left {
        align-items: center;
    }
    .unmasked_left p {
        width: 100%;
    }
    .ur__img_div {
        margin-top: -30%;
        width: 250px;
    }
    .unmk_btn {
        position: relative;
        font-size: 1.5rem;
        padding: 1rem 2rem;
    }
}

@media screen and (max-width: 800px) {
    .unmasked__container {
        padding: 1rem;
    }
    .unmasked__title {
        font-size: 2rem;
    }
    .unmasked_left p {
        font-size: 1.05rem;
    }
    .ur__img_div {
        margin-top: -50%;
        width: 200px;
    }
    .unmk_btn {
        position: relative;
        font-size: 1.25rem;
        padding: 0.75rem 1.75rem;
    }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 400px) {
}

