.events {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
}

.events__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 80%;
}

.events__filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}

.events__filter_btn {
    padding: 0.75rem 1.4rem;
    font-size: 1rem;
    font-family: var(--primary-font);
    font-weight: 500;
    border: none;
    border-radius: 10px;
    background-color:  rgba(217, 217, 217, .1);
    color: #fff;
    cursor: pointer;
}
.events__filter_btn__selected {
    padding: 0.75rem 1.4rem;
    font-size: 1rem;
    font-family: var(--primary-font);
    font-weight: 500;
    border: none;
    border-radius: 10px;
    background-color:  rgba(217, 217, 217, .1);
    color: #fff;
    cursor: pointer;
    filter: drop-shadow(0px 0px 2px #5dcad872) drop-shadow(0px 0px 2px #5dcad872)    drop-shadow(0px 0px 1px #5dcad872) drop-shadow(0px 0px 9px#5dcad872)    drop-shadow(0px 0px 1px #5dcad872) drop-shadow(0px 0px 1px #5dcad872);
   }

.events__card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
}


@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {
    .events__body {
        width: 90%;
    }
}

@media screen and (max-width: 992px) {
    .events {
        padding: 1rem;
    }
    .events__body {
        width: 100%;
    }
    .events__card {
        align-items: center;
        justify-content: center;
    }
    .col {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 800px) {
    .events__filter {
        gap: 1rem;
    }
    .events__filter_btn {
        padding: 0.75rem 1rem;
        font-size: 0.9rem;
        white-space: nowrap;
    }
}

@media screen and (max-width: 600px) {
    .events__filter_btn {
        padding: 0.75rem;
        font-size: 0.8rem;
        white-space: nowrap;
    }
}

@media screen and (max-width: 400px) {

}