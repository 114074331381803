.app {
    background-image: url("./assets/svg/bg_grains.svg"), url("./assets/svg/bg_lines.svg");
    background-color: #02121C; 
    background-position: center; 
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: contain;
    -webkit-animation: backgroundScroll 15s linear infinite;
    -webkit-animation-fill-mode: forwards;
    animation: backgroundScroll 15s linear infinite;
    animation-fill-mode: forwards;  
    min-height: 100vh;
}

.page__header_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    position: relative;
}

.page__header {
    font-size: 64px;
    background: linear-gradient(180deg, #FFFFFF 0%, #40EDF7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
}


@-webkit-keyframes backgroundScroll {
    from {background-position: 0 0;}
    to {background-position: -180px 0px;}
    }
    
    @keyframes backgroundScroll {
    from {background-position: 0 0;}
    to {background-position: -180px 0px;}
    
}


@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 800px) {

}

@media screen and (max-width: 600px) {
    .page__header {
        font-size: xx-large;
        margin: -10px 0 0px;
    }
}

@media screen and (max-width: 400px) {

}