.proShow {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 100vh;
}

.proShow__container {
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: #ffffff;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.proshow_title {
    top: 13rem;
}

.proShow__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 80%;
    margin-left: auto;
}

.proshow_ticket {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.pTicket {
    background: #D9D9D9;
    box-shadow: 4px 2px 26px 12px rgba(0, 0, 0, 0.25);
    border-radius: 0px 25px 25px 0px;
    width: 90%;
    height: 350px;
}