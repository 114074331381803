.eventsCard {
    width: 100%;
    height: 200px;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    cursor: pointer;
}

.ec__imagecontainer, .ec__image_mob_container {
    border-radius: 20px;
    height: 150px;
    width: 150px;
    background: #263743;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    flex-shrink: 0;
}

.ec__img {
    width: 80%;
}

.ec__cardbody {
    height: 100%;
    border-left: 3px solid #fff;
    padding: 0rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.85rem;
}

.ec__cardtitle {
    font-family: var(--secondary-font);
    font-size: 1.5rem;
    color: #9EEFB3;
    margin-top: 0.25rem;
}

.ec__desc {
    font-family: var(--secondary-font);
    font-size: 1rem;
    color: #fff;
}

.ec__cardbuttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    margin-top: 1rem;
}

.ec__cardbtn {
    font-weight: 600;
    padding: 0.5rem 1.2rem;
    color: #fff;
    font-size: 1rem;
    font-family: var(--secondary-font);
    background-color: rgba(0, 44, 73, 1);
    border: none;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
}

.eventsCard__dialog {
    background-image: url("../../../assets/svg/cardBg.svg");
    height: 90vh;
    background-color: #041C2C; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
}

.ecd__left {
    flex: 0.2;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    border-right: 5px solid #072B42;
    height: calc(90vh - 4rem);
    padding: 0 1rem;
    color: #FAFDFF;
}

.ecd__left img {
    width: 100px;
}
.ecd__left h3 {
    font-family: var(--secondary-font);
    font-style: normal;
    font-style: italic;
    font-weight: 700;
    font-size: 1.10rem;
    color: #FAFDFF;
    margin-top: 1rem;
}

.ecd__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}

.ecd_btn {
    border-radius:10px;
    width: 160px;
    height: 45px;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 1.15rem;
    border: none;
    outline: none;
    cursor: pointer;
    color: #FAFDFF;
    background: none;
}
.ecd_btn_selected {
    background: rgba(255, 255, 255, 0.08);
}

.ecd__right {
    flex: 0.8;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #FAFDFF;
    padding-top: 2rem;
    
}

.ecd__name {
    position: absolute;
    top: -2rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.08);
    border-radius: 0px 0px 40px 40px;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 1.75rem;
    color: #FAFDFF;
    padding: 1rem 4rem;
}

.ec_mob_container {
    display: none;
}

@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {
    .eventsCard {
        height: 100%;
        padding: 0.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
    }
    .ec__cardbody, .ec__imagecontainer {
        display: none;
    }
    .ec_mob_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: 2px solid #FFFFFF;
        padding: 2rem;
        border-radius: 15px;
    }
    .ec_mob_header {
        display: flex;
        gap: 1rem;
        border-bottom: 2px solid #FFFFFF;
        padding-bottom: 1rem;
    }
    .ec__image_mob_container {
        height: 120px;
        width: 120px;
    }
    .ec_mob_head_content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .ec__card_mob_title {
        font-family: var(--secondary-font);
        font-style: normal;
        font-weight: 700;
        font-size: 1.10rem;
        color: #9EEFB3;
        margin-top: 1rem;
    }
    .ec__cardbtn {
        font-weight: 600;
        padding: 0.5rem 0.8rem;
        color: #fff;
        font-size: 0.8rem;    }
}

@media screen and (max-width: 800px) {
    .eventsCard {
        padding: 0rem;
        gap: 1rem;
    }
    .ec_mob_container {
        padding: 1rem;
    }
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}