.footer {
    height: 100%;
    padding: 1.5rem 0;
}

.footer_container {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
}

.logo {
    width: 230px;
    cursor: pointer;
}

.clglogo {
    object-fit: cover;
    width: 100%;
}

.logo.excel_logo {
    width: 230px;
}

.excellogo {
    object-fit: cover;
    width: 100%;
}

.social_icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.socialicon {
    cursor: pointer;
    font-size: 1.8rem;
    color: #C8DDEC ;
}

.socialicon:hover {
    filter: drop-shadow(0px 0px 57.8592px rgba(125, 205, 232, 0.5)) drop-shadow(0px 0px 33.0624px rgba(125, 205, 232, 0.5)) drop-shadow(0px 0px 19.2864px rgba(125, 205, 232, 0.5)) drop-shadow(0px 0px 9.64321px rgba(125, 205, 232, 0.5)) drop-shadow(0px 0px 2.7552px rgba(125, 205, 232, 0.5)) drop-shadow(0px 0px 1.3776px rgba(125, 205, 232, 0.5));
}

.footer_text {
    font-size: 1.12rem;
    font-family: var(--primary-font);
    color: #C8DDEC;
    font-weight: 400;
}

.heart_icon {
    color: #3F7CBF;
    margin-bottom: -0.25rem;
    padding: 0 0.25rem;
    box-sizing: content-box;
}

@media only screen and (max-width:1200px){
    .logo {
        width: 270px;
    }

    .logo.excel_logo {
        width: 270px;
    }

}

@media only screen and (max-width: 1100px) {
    .logo {
        width: 250px;
    }

    .logo.excel_logo {
        width: 250px;
    }
    
    .socialicon {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 992px) {

    .details {
        gap: 1.5rem;
    }

    .footer_text{
        font-size: 1.01rem;
    }

    .clglogo, .excellogo {
        width: 80%;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .logo.excel_logo {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

}

@media screen and (max-width: 800px) {
    .footer {
        padding: 1rem;
    }
    .logo {
        width: 210px;
    }

    .logo.excel_logo {
        width: 210px;
    }
    
    .socialicon {
        font-size: 1.4rem;
    }

    .details {
        gap: 1.15rem;
    }

    .clglogo, .excellogo {
        width: 80%;
    }

}

@media screen and (max-width: 600px) {
    .footer_container {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
    }
    .logo {
        order: 1;
    }

    .details {
        order: 2;
        margin-top: 1.2rem;
    }
    .logo {
        width: 160px;
    }

    .logo.excel_logo {
        width: 160px;
    }
    
    .socialicon {
        font-size: 1.25rem;
    }

    .details {
        gap: 1rem;
    }

    .footer_text{
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 400px) {
    .logo {
        width: 140px;
    }

    .logo.excel_logo {
        width: 140px;
    }
}