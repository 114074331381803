.profilePage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}


.profile__avatar {
    position: absolute;
    right: 3rem;
    cursor: pointer;
}

.logout_div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.logout_div button {
    background: #002C49;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    border: none;
    font-family: var(--secondary-font);
    font-style: normal;
    font-size: 1.2rem;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    padding: 0.5rem 1rem;
}

.profile__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem auto;
    background: #00203C;
    border-radius: 10px;
    padding: 2rem;
}

.profile__box p {
    color: #ffffff;
    font-family: var(--primary-font);
    font-size: 1.15rem;
}
.profile__box p span {
    font-family: var(--secondary-font);
    font-size: 1.25rem;
    font-weight: 600;
    margin-left: 0.25rem;
    color: #9EEFB3;
}

@media screen and (max-width: 992px) { 
    .profile__avatar {
        right: 2.5rem;
    }
}

@media screen and (max-width: 800px) {
    .profile__avatar {
        right: 1.5rem;
    }
    .profile__box {
        margin: 1rem;
        padding: 1rem;
    }
}